import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="gallery" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
          </div>
          <div className="row">
            <div className="gallery-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/lexus.jpg"
                      title="Lexus"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/lexus.jpg"
                        className="img-responsive"
                        alt="lexus"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/r33.jpg"
                      title="R33"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/r33.jpg"
                        className="img-responsive"
                        alt="R33"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/swift-white.jpg"
                      title="Suzuki Swift"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/swift-white.jpg"
                        className="img-responsive"
                        alt="Swift"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/s13-black-blue.jpg"
                      title="S13s"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/s13-black-blue.jpg"
                        className="img-responsive"
                        alt="2 S13s"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/s13-white.jpg"
                      title="S13"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/s13-white.jpg"
                        className="img-responsive"
                        alt="S13 White"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/180sx.jpg"
                      title="180SX"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/180sx.jpg"
                        className="img-responsive"
                        alt="180SX"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/jzx100.jpg"
                      title="JZX100"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/jzx100.jpg"
                        className="img-responsive"
                        alt="jzx100"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/gtr.jpg"
                      title="GTR"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/gtr.jpg"
                        className="img-responsive"
                        alt="gtr"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="gallery-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/hks.jpg"
                      title="HKS Gearbox"
                      data-lightbox-gallery="gallery1"
                    >
                      <img
                        src="img/portfolio/hks.jpg"
                        className="img-responsive"
                        alt="hks"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
