import React, { useState } from "react";
import { Axios } from "firebase/firebaseConfig";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useForm from "hooks/";

const Contact = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitContact = async () => {
    try {
      setLoading(true);
      const token = await executeRecaptcha('contact');
      if(!token) {
        setError("Sorry! Recaptcha not ready");
      }

      let result = await verifyToken(token);
      
      if(!result || result.score < 5) {
        setError("Sorry! Recaptcha Token invalid");
      } else {
        setSuccess(false);
        setError(false);
        await sendEmail();
      }
    }
    finally {
      setLoading(false);
    }
  };

  const verifyToken = async (token) => {
    try {
      let response = await Axios.post(`https://us-central1-rp-imports.cloudfunctions.net/sendRecaptcha`, {
        secret:process.env.REACT_APP_SITE_KEY, token
      });
      return response.data;
    } catch(error) {
      return null;
    }
  }

  const { values, handleChange, handleSubmit } = useForm(handleSubmitContact);

  const sendEmail = async () => {
    try {
      await Axios.post(
        "https://us-central1-rp-imports.cloudfunctions.net/submit",
        values
      )
      setSuccess(true);
    }
    catch (error){
      setError(true);
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Want something Imported?</h2>
                <p>
                  Fill out the form below to send us an email and we will get
                  back to you as soon as possible. Otherwise, reach out on
                  Facebook, we'll be happy to help.
                </p>
              </div>
              <form onSubmit={handleSubmit} id="contactForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required="required"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <p className="help-block text-light"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={values.email}
                      />
                      <p className="help-block text-light"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    onChange={handleChange}
                    value={values.message}
                    required
                  ></textarea>
                  <p className="help-block text-light"></p>
                </div>
                {success && <div id="success">Email Sent!</div>}
                {error && (
                  <div id="error">Email Failed to Send, try again later...</div>
                )}
                {!loading && 
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button> 
                }
                {loading && <div className="fa-2x"><i className="fa fa-cog fa-spin"></i></div>}
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {data ? data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {data ? data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={data ? data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data ? data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; R P Imports 2017 Ltd.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
